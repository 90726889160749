import React, { memo, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import io from 'socket.io-client';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Connection, PublicKey } from '@solana/web3.js';
import { MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBDropdown,MDBDropdownToggle, MDBBadge, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBTypography, MDBBtn, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import GifSelector from './GifSelector';
import EmojiSelector from './EmojiSelector';
import { format, isToday, isYesterday } from 'date-fns';
import './ChatRoom.css';
import gifIcon from '../assets/gifIcon.png';
import emojiIcon from '../assets/emojiIcon.png';
import { Circles } from 'react-loader-spinner';
import xIcon from '../assets/xIcon.png';
import chartsImage from '../assets/charts.png';
import internet from '../assets/internet.png';
import plus from '../assets/plus.png';
import solanaLogo from '../assets/solanaLogo.png';
import telegramIcon from '../assets/telegramIcon.png';
import replyLeft from '../assets/replyLeft.png';
import replyRight from '../assets/replyRight.png';
import sendIcon from '../assets/send.png';
import reactIcon from '../assets/reactIcon.png';
import gift from '../assets/gift.png';
import gif from '../assets/gif.png';
import smiley from '../assets/smiley.png';
import burger from '../assets/burger.png';
import axios from 'axios';
require('dotenv').config();

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const getChangeColor = (change) => {
  if (!change || change === 'N/A') return 'white';

  const changeNumber = parseFloat(change.replace('%', ''));
  return changeNumber < 0 ? '#EA0F0B' : '#2BC016';
};

const TipCommandInput = memo(({ walletAddress, amount, comment, onAmountChange, onCommentChange, onKeyDown }) => {
  return (
    <div className="command-input">
      <span className="command-prefix">/tip</span>
      <input
        className="command-input-box wallet-address-box"
        type="text"
        value={walletAddress}
        readOnly // Make the wallet address non-editable
      />
      <input
        className="command-input-box amount-box"
        type="text"
        placeholder="Amount"
        value={amount}
        onChange={onAmountChange}
        onKeyDown={onKeyDown} // Attach onKeyDown here
      />
      <input
        className="command-input-box comment-box"
        type="text"
        placeholder="Comment (optional)"
        value={comment}
        onChange={onCommentChange}
        onKeyDown={onKeyDown} // And here
      />
    </div>
  );
});


const ChatRoom = ({ selectedToken }) => {
  const { publicKey, connected } = useWallet();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');
  const [skip, setSkip] = useState(0);
  const [selectedWalletAddress, setSelectedWalletAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [userCache, setUserCache] = useState({});
  const [showGifSelector, setShowGifSelector] = useState(false);
  const [showEmojiSelector, setShowEmojiSelector] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [isTipMode, setIsTipMode] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [reactionPanel, setReactionPanel] = useState(null);
  const [image, setImage] = useState(null); 
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const chatBoxRef = useRef(null);
  const reactionPanelRef = useRef(null);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true); // Track if user is at the bottom
  const apiUrl = process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL_PREVIEW || '';
  const socketUrl = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000'; // Use your environment variable
  const socket = useMemo(() => io(socketUrl, { reconnection: true, reconnectionAttempts: 5, reconnectionDelay: 1000 }), [socketUrl]);
  const fetchingUsers = useRef({});

  const fetchUserData = (userPublicKey) => {
    if (!userCache[userPublicKey] && !fetchingUsers.current[userPublicKey]) {
      fetchingUsers.current[userPublicKey] = true;
      axios
        .get(`${apiUrl}/api/user/${userPublicKey}`)
        .then((response) => {
          setUserCache((prevCache) => ({
            ...prevCache,
            [userPublicKey]: response.data,
          }));
          fetchingUsers.current[userPublicKey] = false;
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          fetchingUsers.current[userPublicKey] = false;
        });
    }
  };

  
  const Loader = () => (
    <Circles height="15" width="15" color="white" ariaLabel="loading" wrapperClass="messageLoader" />
  );

  const fetchMessages = useCallback(async () => {
    if (!selectedToken) return;
    setLoading(true);
    try {
      const currentScrollHeight = chatBoxRef.current.scrollHeight;
      const scrollTop = chatBoxRef.current.scrollTop;
      const response = await axios.get(`${apiUrl}/api/chatroom/${selectedToken.mint}/messages`, {
        params: { skip, limit: 20 }
      });

      setMessages((prevMessages) => {
        const messageIds = new Set(prevMessages.map(m => m._id));
        const newMessages = response.data.filter(msg => !messageIds.has(msg._id));
        return [...newMessages, ...prevMessages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      });
      setSkip((prevSkip) => prevSkip + 20);

      setTimeout(() => {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight - currentScrollHeight + scrollTop;
      }, 0);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  }, [apiUrl, selectedToken, skip]);




  const checkTokenOwnership = useCallback(async () => {
    if (selectedToken.mint === '0xa43fe16908251ee70ef74718545e4fe6c5ccec9f') {
      setHasToken(true);
      return;
    }
    if (connected && publicKey && selectedToken) {
      try {
        const response = await axios.post(`${apiUrl}/api/check-token-ownership`, {
          publicKey: publicKey.toBase58(),
          mint: selectedToken.mint
        });

        const { hasToken } = response.data;
        setHasToken(hasToken);
        console.log('Has token:', selectedToken);
      } catch (error) {
        console.error('Error checking token ownership:', error);
      }
    }

  }, [connected, publicKey, selectedToken, apiUrl]);

  useEffect(() => {
    setMessages([]);
  }, [selectedToken]);

  useEffect(() => {
    checkTokenOwnership();

  }, [checkTokenOwnership]);

  useEffect(() => {
    const handleEscKeyPress = (event) => {
      if (event.key === 'Escape') {
        setReplyTo(null);
      }
    };

    document.addEventListener('keydown', handleEscKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);


  useEffect(() => {
    if (hasToken && selectedToken) {
      console.log('Joining room:', selectedToken.mint);
      socket.emit('joinRoom', selectedToken.mint);

      const handleRoomData = (data) => {
        setMessages((prevMessages) => {
          const messageIds = new Set(prevMessages.map(m => m._id));
          const newMessages = data.filter(msg => !messageIds.has(msg._id));
          return [...newMessages, ...prevMessages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        });
        console.log('Room data received:', data);
        if (isUserAtBottom) {
          scrollToBottom();
        }
      };

      const handleMessage = (message) => {
        setMessages((prevMessages) => {
          const messageIds = new Set(prevMessages.map(m => m._id));
          if (messageIds.has(message._id)) return prevMessages; // Avoid duplicate messages
          const updatedMessages = [...prevMessages, message];
          return updatedMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        });
        console.log('New message received:', message);
        if (isUserAtBottom) {
          scrollToBottom();
        }
      };

      const handleReaction = ({ messageId, reactions }) => {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === messageId ? { ...msg, reactions } : msg
          )
        );
      };

      socket.on('roomData', handleRoomData);
      socket.on('message', handleMessage);
      socket.on('reaction', handleReaction);

      return () => {
        socket.emit('leaveRoom', selectedToken.mint);
        socket.off('roomData', handleRoomData);
        socket.off('message', handleMessage);
        socket.off('reaction', handleReaction);
      };
    }
  }, [hasToken, selectedToken, isUserAtBottom, socket]);



  useEffect(() => {
    if (isUserAtBottom) {
      // Use setTimeout to ensure DOM has updated
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  }, [messages, isUserAtBottom]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (reactionPanel && reactionPanelRef.current && !reactionPanelRef.current.contains(event.target)) {
        setReactionPanel(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [reactionPanel]);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const handleAmountChange = useCallback((e) => {
    setAmount(e.target.value);
  }, []);

  const handleCommentChange = useCallback((e) => {
    setComment(e.target.value);
  }, []);



  const handleScroll = debounce(() => {
    if (chatBoxRef.current.scrollTop === 0 && !loading) {
      fetchMessages();
    }
    const { scrollHeight, scrollTop, clientHeight } = chatBoxRef.current;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + 20;
    setIsUserAtBottom(isAtBottom);
  }, 200);




  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.metaKey || event.ctrlKey) {
        setMessage((prevMessage) => prevMessage + '\n');
      } else {
        event.preventDefault();
        sendMessage();
      }
    }
  };

  const sendMessage = async () => {
    if (isTipMode) {
      const userA = publicKey ? publicKey.toBase58() : 'Unknown';
      const userB = selectedWalletAddress;

      const getUserData = async (userPublicKey) => {
        if (userCache[userPublicKey]) {
          return userCache[userPublicKey];
        } else {
          try {
            const response = await axios.get(`${apiUrl}/api/user/${userPublicKey}`);
            setUserCache((prevCache) => ({
              ...prevCache,
              [userPublicKey]: response.data,
            }));
            return response.data;
          } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
          }
        }
      };

      const userAData = await getUserData(userA);
      const userBData = await getUserData(userB);

      const displayUserA = userAData && userAData.socialsLinked && userAData.username
        ? userAData.username
        : userA.slice(0, 4) + '...' + userA.slice(-4);
      const displayUserB = userBData && userBData.socialsLinked && userBData.username
        ? userBData.username
        : userB.slice(0, 4) + '...' + userB.slice(-4);

      const tipMessage = `${displayUserA} tipped ${amount} $${selectedToken.symbol} to ${displayUserB} ${
        comment ? ':"' + comment + '".' : 'and did not leave a comment.'
      }`;

      const newMessage = {
        _id: 'tip-' + Date.now(),
        roomName: selectedToken.mint,
        user: 'Admin',
        message: tipMessage,
        timestamp: new Date().toISOString(),
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsTipMode(false);
      setAmount('');
      setComment('');
      setMessage(''); 
    }else if (message.trim() || image) {
      let imageUrl = null;
      if (image) {
        const formData = new FormData();
        formData.append('image', image);
        const response = await axios.post(`${apiUrl}/api/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        imageUrl = response.data.url;
      }
      const newMessage = {
        roomName: selectedToken.mint,
        user: publicKey ? publicKey.toBase58() : 'Anonymous',
        message,
        timestamp: new Date().toISOString(),
        replyTo: replyTo ? replyTo._id : null,
        imageUrl: image ? URL.createObjectURL(image) : null
      };
      console.log('Sending new message:', newMessage);
      socket.emit('sendMessage', newMessage);
      setMessage('');
      setReplyTo(null);
      setImage(null);
      setTimeout(scrollToBottom, 100);
    }
  };


  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
  };

  const sendGif = (gifUrl) => {
    const newMessage = {
      roomName: selectedToken.mint,
      user: publicKey ? publicKey.toBase58() : 'Anonymous',
      message: `<img src="${gifUrl}" alt="GIF" />`,
      timestamp: new Date().toISOString()
    };
    socket.emit('sendMessage', newMessage);
    setShowGifSelector(false);
  };

  const addEmoji = (event) => {
    setMessage((prevMessage) => prevMessage + event.emoji);
    setShowEmojiSelector(false);
  };

  const renderUsername = (msg, isOwner) => {
    if (msg.user === 'Admin') {
      return <span className="usernameLink admin-message">Admin</span>;
    }

    // Existing logic for rendering usernames
    const randomPercentage = msg.randomPercentage || (Math.random() * 7.99 + 0.01).toFixed(2);
    const percentageValue = parseFloat(randomPercentage);
    const green = Math.round((percentageValue / 8) * 255);
    const yellow = 255 - green;
    const color = `rgb(${yellow}, ${green}, 0)`;

    if (msg.socialsLinked) {
      return (
        <>
          <a className="usernameLink" href={`https://solscan.io/account/${msg.user}`} target="_blank" rel="noopener noreferrer">
            {isOwner ? 'You' : msg.username}
            <span style={{ color: color }}> ({randomPercentage}%)</span>
          </a>
        </>
      );
    }

    return (
      <a className="usernameLink" href={`https://solscan.io/account/${msg.user}`} target="_blank" rel="noopener noreferrer">
        {isOwner ? 'You' : msg.user.slice(0, 4) + '...' + msg.user.slice(-4)}
        <span style={{ color: color }}> ({randomPercentage}%)</span>
      </a>
    );
  };


  const renderMessageContent = (msg) => {
    console.log(msg)
    if (msg.user === 'Admin') {
      return <span className="admin-message-content">{msg.message}</span>;
    }

    // Existing logic for rendering message content
    if (msg.message.startsWith('<img src=')) {
      const src = msg.message.match(/<img src="([^"]+)" alt="GIF" \/>/)[1];
      return <img src={src} alt="GIF" width="200" />;
    } else if (msg.imageUrl) {
      return <img src={msg.imageUrl} alt="Attached" width="100" />;
    } else {
      return msg.message;
    }
  };


  const formatSimpleTimeDistance = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
    };

    for (const [key, value] of Object.entries(intervals)) {
      const count = Math.floor(seconds / value);
      if (count >= 1) {
        if (key === 'minute') return `${count} min ago`;
        if (key === 'hour') return `${count} h ago`;
        if (key === 'day') return `${count} d ago`;
        if (key === 'week') return `${count} week ago`;
        if (key === 'month') return `${count} month ago`;
        if (key === 'year') return `${count} year${count > 1 ? 's' : ''} ago`;
      }
    }
    return 'just now';
  };




  const groupReactions = (reactions = []) => {
    const groupedReactions = {};
    reactions.forEach((reaction) => {
      if (!groupedReactions[reaction.emoji]) {
        groupedReactions[reaction.emoji] = { count: 0, users: [] };
      }
      groupedReactions[reaction.emoji].count += 1;
      groupedReactions[reaction.emoji].users.push({pubKey: reaction.user,username: reaction.username,pfp: reaction.profilePicture,socialsLinked: reaction.socialsLinked});
    });
    return groupedReactions;
  };

  const renderReactions = (reactions) => {

    const groupedReactions = groupReactions(reactions);

    return Object.entries(groupedReactions).map(([emoji, { count, users }], index) => (
      <span key={index} className="reaction">
        {emoji} {count > 1 ? <sup>{count}</sup> : null}
        <div className="tooltip">
          {users.map((user, userIndex) => (
            <div key={userIndex} className="reaction-user">
              <img src={user.pfp || 'https://cdn-icons-png.flaticon.com/512/3177/3177440.png'} alt="Profile" className="rounded-circle me-2" width="20" height="20"/>
              {user.socialsLinked ? (
                <span className="usernameLink">{`${user.username}`}</span>
              ) : (
                <span>{user.pubKey.slice(0, 4)}...{user.pubKey.slice(-4)}</span>
              )}
            </div>
          ))}
        </div>
      </span>
    ));
  };

  const chooseReactionTo = (msgId) => {
    setReactionPanel(prevPanel => (prevPanel === msgId ? null : msgId));
  };

  const sendTipTo = (msg) => {
    const tipMessage = `/tip ${msg.user} `;
    setSelectedWalletAddress(msg.user)
    setMessage(tipMessage); // Prepopulate the message input with the tip format
    setIsTipMode(true);
  };


  const renderReplyTo = (msg, isOwner) => {
    // const originalMessage = messages.find(m => m._id === msg.replyTo);
    // if (originalMessage) {
    //   return (
    //     <MDBCard className={`mask-custom message ${isOwner? 'sentByOwner':'sentByUser'} replied`}>
    //       <MDBCardHeader className="d-flex justify-content-between p-2" style={{ borderBottom: '1px solid rgba(255,255,255,.3)'}}>
    //           <img src={originalMessage.profilePicture || 'https://cdn-icons-png.flaticon.com/512/3177/3177440.png'} alt="Profile" className="rounded-circle me-2" width="20" height="20"/>
    //       <div className="userInfoMessage d-flex position-relative">
    //         {renderUsername(originalMessage,isOwner)}
    //       </div>
    //       </MDBCardHeader>
    //       <MDBCardBody className="p-2">
    //         <p className="mb-0">
    //           {renderMessageContent(originalMessage)}
    //         </p>
    //       </MDBCardBody>
    //     </MDBCard>
    //   );
    // }
    return null;
  };

  const handleMouseEnter = (messageId) => {
    setSelectedMessageId(messageId);
  };

  const handleMouseLeave = () => {
    setSelectedMessageId(null);
  };

  const handleReactionClick = useCallback(async (messageId, emoji) => {
    try {
      const response = await axios.post(`${apiUrl}/api/reaction`, {
        roomName: selectedToken.mint,
        messageId,
        emoji,
        user: publicKey.toBase58()
      });
      if (response.data.success) {
        console.log('Reaction added/updated successfully');
        // Emit the reaction event to update other users
      }
    } catch (error) {
      console.error('Error adding reaction:', error);
    }
    setReactionPanel(null);
  }, [apiUrl, publicKey, selectedToken]);





  return (
      <div className="text-white chatArea">
        {connected ? (
          hasToken ? (
            <>
            <div className="leftPanel d-flex flex-column justify-content-between">
              <div className="tokenHeader justify-content-between">
                <div className="tokenLogoName d-flex flex-row">
                      <img
                        src={selectedToken.logo || 'https://via.placeholder.com/150'}
                        alt="token-logo"
                        className="img-fluid rounded-circle tokenLogo"
                      />
                      <MDBTypography tag="h5" className="text-white mx-3 m-auto">${selectedToken.symbol}</MDBTypography>
                </div>
                    <div className="tokenSocials d-none">
                       { selectedToken.links.twitter ? (
                          <a href={selectedToken.links.twitter} target="_blank"><img src={xIcon} alt="Profile"></img></a>
                        ) : '' }
                       { selectedToken.links.telegram ? (
                          <a href={selectedToken.links.telegram} target="_blank"><img src={telegramIcon} alt="Profile"></img></a>
                        ) : '' }
                       { selectedToken.links.website ? (
                          <a href={selectedToken.links.website} target="_blank"><img src={internet} alt="Profile"></img></a>
                        ) : '' }       
                    </div>
                    <div className="tokenMoreInfo d-flex flex-row mb-0">
                        <span className="m-auto">
                          123 members
                        </span>
                        <MDBDropdown>
                          <MDBDropdownToggle tag='a' className='nav-link hidden-arrow' href='#'>
                            <img src={burger} alt="Profile" className="rounded-circle me-2" width="24" height="24"/>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu>
                            <MDBDropdownItem link>Holders stats</MDBDropdownItem>
                            <MDBDropdownItem link>Notification settings</MDBDropdownItem>
                            <MDBDropdownItem link>Another cool feature</MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                    </div>
              </div>

              <div className="chatBox d-flex flex-column">
                <div ref={chatBoxRef} onScroll={handleScroll} style={{ width: '50w', overflowY: 'scroll', borderRadius: '10px' }} className='chatCol d-flex flex-column'>
                { loading && <Loader />}
                {messages.map((msg, index) => {
                  const isOwner = msg.user === publicKey.toBase58();
                  const isAdminMessage = msg.user === 'Admin';
                  const isNotAGif = !msg.message.startsWith('<img src=')
                  console.log(`isNotAGif ${isNotAGif}`)
                  return (
                    <MDBCard key={msg._id} className={`messageCard position-relative ${isAdminMessage ? 'admin-message-card' : ''}`}>
                      {!isAdminMessage && (
                        <div className={`messagePfp ${isOwner ? 'pfpReply' : 'pfpSender'}`}>
                          <img
                            src={msg.profilePicture || 'https://cdn-icons-png.flaticon.com/512/3177/3177440.png'}
                            alt="Profile"
                            className="rounded-circle me-2"
                            width="30"
                            height="30"
                          />
                          {msg.socialsLinked && (
                            <a href={`https://x.com/${msg.username}`} target="_blank" rel="noopener noreferrer">
                              <img src={xIcon} alt="Profile" className="socialsVerified" />
                            </a>
                          )}
                        </div>
                      )}
                      {msg.replyTo && renderReplyTo(msg, isOwner)}
                      <div
                        className={`message ${isOwner ? 'sentByOwner' : 'sentByUser'} ${isAdminMessage ? 'adminMessage' : ''} ${
                          msg._id === (replyTo && replyTo._id) ? 'selected' : ''
                        }`}
                        onMouseEnter={() => handleMouseEnter(msg._id)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {!isAdminMessage && (
                          <div className="d-flex p-2">
                            <div className="usernameInfo">
                              {renderUsername(msg, isOwner)}
                              <span className="separator">·</span>
                              <span>{msg.timestamp ? formatSimpleTimeDistance(new Date(msg.timestamp)) : 'Unknown time'}</span>
                            </div>
                          </div>
                        )}

                        <div className={`messageWrapper ${isNotAGif ? (isOwner ? 'reply' : 'sender') : ''}`}>
                          <p className="mb-0">{renderMessageContent(msg)}</p>
                          {!isAdminMessage && isNotAGif && (
                            <>
                              <div className="reactions">{renderReactions(msg.reactions || [])}</div>
                              <div className={`message-actions ${isOwner ? 'actionsOwner' : 'actionsUser'}`}>
                                {/* ...buttons for reply, react, tip */}
                                <button className="replyButton" onClick={() => setReplyTo(msg)}>
                                  <img src={isOwner ? replyRight : replyLeft} className="replyIcon" alt="Reply" />
                                </button>
                                <button className="replyButton" onClick={() => chooseReactionTo(msg._id)}>
                                  <img src={reactIcon} className="replyIcon" alt="React" />
                                </button>
                                <button className="replyButton" onClick={() => sendTipTo(msg)}>
                                  <img src={sendIcon} className="sendIcon" alt="Tip" />
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </MDBCard>
                  );
                  })}

                </div>
                <div className="d-flex align-items-center mt-3 chatRectangle flex-column">
                {replyTo && (
                    <MDBCardHeader className="d-flex justify-content-between p-2 replyingTo position-relative" style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }}>
                      <button className="btn-close btn-close-white" onClick={() => setReplyTo(null)}></button>
                      <div className="userInfoMessage d-flex position-relative">
                        <span>Replying to {replyTo.username ? `${replyTo.username}` : `${replyTo.user.slice(0, 4)}...${replyTo.user.slice(-4)}`}</span>
                      </div>
                    </MDBCardHeader>
                )}

                  <div className={`form-outline form-white d-flex ${replyTo ? 'replyingChatBox' : ''}`} id="textAreaChatRoom">
                    <img src={plus} alt="plus" className="plus" />
                    <div style={{ position: 'relative', width: '100%' }}>
                      {/* Conditionally render the TipCommandInput if in tip mode */}
                      {isTipMode ? (
                        <TipCommandInput
                          walletAddress={selectedWalletAddress.slice(0, 4) + `...` + selectedWalletAddress.slice(-4)}
                          amount={amount}
                          comment={comment}
                          onAmountChange={handleAmountChange}
                          onCommentChange={handleCommentChange}
                          onKeyDown={handleKeyDown} // Attach the onKeyDown handler
                        />
                      ) : (
                        <>
                          {/* Show label if no message is present */}
                          {!message && (
                            <label 
                              className="form-label" 
                              htmlFor="textAreaChatRoom" 
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '10px',
                                transform: 'translateY(-50%)',
                                color: 'rgba(255,255,255,0.5)',
                                pointerEvents: 'none'
                              }}
                            >
                              Write a message...
                            </label>
                          )}
                          {/* Render the regular textarea when not in tip mode */}
                          <textarea 
                            className={`form-control ${message.startsWith('/tip') ? 'tip-mode' : ''}`}
                            rows="1" 
                            style={{resize: 'none', paddingLeft: '10px'}}
                            value={message} 
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeyDown} // Trigger sendMessage on Enter
                          ></textarea>
                        </>
                      )}
                    </div>
                    {/* <input type="file" onChange={handleFileChange} /> */}
                    <div className="selectors">
                      <button
                        type="button"
                        className="btn btn-link text-white"
                        style={{ padding: 0 }}
                      >
                        <img src={gift} alt="Emoji" style={{ width: '24px', height: '24px' }} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-link text-white"
                        style={{ padding: 0 }}
                        onClick={() => setShowGifSelector(!showGifSelector)}
                      >
                        <img src={gif} alt="GIF" style={{ width: '24px', height: '24px' }} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-link text-white"
                        onClick={() => setShowEmojiSelector(!showEmojiSelector)}
                        style={{ padding: 0 }}
                      >
                        <img src={smiley} alt="Emoji" style={{ width: '24px', height: '24px' }} />
                      </button>

                    </div>
                    {showEmojiSelector && (
                      <div className="emoji-selector-container">
                        <EmojiSelector onEmojiSelect={addEmoji} />
                      </div>
                    )}
                    {showGifSelector && (
                      <div className="gif-selector">
                        <GifSelector onGifSelect={sendGif} />
                      </div>
                    )}
                  </div>
                  {/* <MDBBtn color="light" size="lg" id="sendButton" rounded className="float-end" onClick={sendMessage}>Send</MDBBtn> */}
                </div>
              </div>
            </div>
            <div className="rightPanel">
             <MDBCol className="d-flex flex-column rightPanelCol position-relative">
                <div className="treasury rightPanelCard position-relative d-flex flex-column justify-content-center" >
                  <img src={solanaLogo} alt="Profile" className="treasuryBackground"/>
                  <h4 className="text-center rightPanelTitle">Treasury</h4>
                  <img src={solanaLogo} alt="Profile" className="rounded-circle me-2" width="40" height="40"/>
                  <div className="treasuryAmount">
                    <h3 className="text-center">
                      <span className="amount">325</span>
                      <span className="currency"> SOL</span>
                      <br></br>
                      <p className="contribution">Contribution: 5% (16 SOL)</p>
                    </h3>
                  </div>
                  <div className="donate justify-content-between">
                    <p className="mb-0">Enter amount</p>
                    <button size="lg" rounded className="treasuryButton">Donate</button>
                  </div>
                </div>
                <div className="treasury rightPanelCard position-relative d-flex flex-column justify-content-center" >
                  <h4 className="text-center rightPanelTitle">Voting</h4>
                  <div className="votingOutcome">
                    <h3 className="text-center">
                      <span className="amount">NOT PASSING</span>
                    </h3>
                  </div>
                  <div className="votePanel">
                    <div className="task-progress">
  
                      <div className="combined-progress">
                        <div
                          className="progress-segment yes-segment"
                          style={{ width: '36%' }}
                          title="Yes: 36%"
                        ></div>
                        <div
                          className="progress-segment no-segment"
                          style={{ width: '64%' }}
                          title="No: 64%"
                        ></div>
                      </div>
                      <div className="d-flex flex-rox justify-content-between voteText mt-2">
                        <p>
                          Aye: 36%
                        </p>
                        <p>
                          Nay: 64%
                        </p>
                      </div>
                    </div>
                  </div>

                  <button size="lg" rounded className="voteButton">Vote</button>
                </div>
                <div className="treasury rightPanelCard position-relative d-flex flex-column justify-content-center mb-0" >
                  <h4 className="text-center rightPanelTitle" style={{height:'4vh'}}>Analytics</h4>
                    <h3 className="d-flex flex-row mr-auto" style={{height:'4vh'}}>
                      <span className="analyticsAmount">$10,342</span>
                      <span className="analyticsGrowth">
                        <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                          <path d="M183.6 724h656.8c19.7 0 30.7-20.8 18.5-35L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L162.1 689c-12.2 14.2-1.2 35 18.5 35z"></path>
                        </svg>
                        0.30%
                      </span>
                    </h3>
                  <img src={chartsImage} style={{height:'18vh', width:'100%'}}  className="m-auto"></img>
                </div>
             </MDBCol>

            </div>
            </>
          ) : (
            <p className="text-white">You do not hold the necessary token to access this chat room.</p>
          )
        ) : (
          <div>
            <p className="text-white">Please connect your wallet to access the chat room.</p>
            <WalletMultiButton />
          </div>
        )}

        </div>
  );
};

export default ChatRoom;


{/*                     <div className="d-inline-flex justify-content-around">
                      <MDBCol size="2" className="d-flex flex-column justify-content-around">
                        <MDBTypography tag="h6" className="text-muted mb-0">Market Cap</MDBTypography>
                        <MDBTypography tag="p" className="text-white mb-0">${selectedToken.marketCap || 'N/A'}</MDBTypography>
                      </MDBCol>
                      <MDBCol size="2" className="d-flex flex-column justify-content-around">
                        <MDBTypography tag="h6" className="text-muted mb-0">Volume (24h)</MDBTypography>
                        <MDBTypography tag="p" className="text-white mb-0">${selectedToken.volume24 || 'N/A'}</MDBTypography>
                      </MDBCol>
                      <MDBCol size="2" className="d-flex flex-column justify-content-around">
                        <MDBTypography tag="h6" className="text-muted mb-0">Price Change (24h)</MDBTypography>
                        <MDBTypography tag="p" className="mb-0" style={{ color: getChangeColor(selectedToken.change24)}}>{selectedToken.change24 || 'N/A'}</MDBTypography>
                      </MDBCol>
                    </div> */}