import React, { useEffect, useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import logo from '../assets/logo.png';
import './Navbar.css';
import axios from 'axios';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL_PREVIEW || '';
const Navbar = ({ setShowProfile }) => {
  const { publicKey } = useWallet();
  const [profile, setProfile] = useState({});
  const [showNav, setShowNav] = React.useState(false);

  const fetchProfile = async () => {
    try {
      if (publicKey) {
        const response = await axios.get(`${apiUrl}/api/user/${publicKey.toString()}`, { withCredentials: true });
        setProfile(response.data);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [publicKey]);

  const handleProfileClick = () => {
    setShowProfile(true);
  };

  return (
    <MDBNavbar expand='lg' light>
      <MDBContainer fluid>
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          style={{color: 'white'}}
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>


          <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
          <MDBNavbarBrand href='/'>
            <img
              src={logo}
              height='24'
              alt='MDB Logo'
              loading='lazy'
              className='logoNav'
            />
          </MDBNavbarBrand>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-link text active" active as={Link} to='/'>Chat
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-link text" as={Link} to='/team'>Label
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-link text" as={Link} to='/projects'>Label
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>


        <div className='d-flex align-items-center right-nav-menu'>
          <div>
            <a className='text-reset' href='#'>
              <MDBIcon far icon='envelope' />
            </a>
          </div>

          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link hidden-arrow' href='#'>
              <MDBIcon fas icon='bell' />
              <MDBBadge color='danger' notification pill>
                1
              </MDBBadge>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>Some news</MDBDropdownItem>
              <MDBDropdownItem link>Another news</MDBDropdownItem>
              <MDBDropdownItem link>Something else here</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>

          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link hidden-arrow d-flex align-items-center pfp' href='#'>
              <img
                src={profile.profilePicture || 'https://via.placeholder.com/150'}
                className='rounded-circle pfp'
                height='25'
                alt='Profile'
                loading='lazy'
              />
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>My profile</MDBDropdownItem>
              <MDBDropdownItem link>Settings</MDBDropdownItem>
              <MDBDropdownItem link>Logout</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>

          <WalletMultiButton className="btn btn-primary ms-3" />
        </div>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
