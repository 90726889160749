import React, { useMemo, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import WalletConnect from './components/WalletConnect';
import ChatRoom from './components/ChatRoom';
import Navbar from './components/Navbar';
import ProfilePage from './components/ProfilePage';
import PasswordProtect from './components/PasswordProtect';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import CollapseIcon from './assets/collapse.png';
import './App.css';
import { MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import './components/AuthStyles.css';
import Helmet from "react-helmet"
import axios from 'axios';
import Cookies from 'js-cookie';
require('dotenv').config();
const apiUrl = process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL_PREVIEW || '';

const AppContent = ({
  handlePasswordSubmit,
  isAuthenticated,
  setSelectedToken,
  selectedToken,
  showProfile,
  setShowProfile,
  isWrapped,
  setIsWrapped
}) => {
  const { connected } = useWallet();

  // Check if the user is authenticated first
  if (!isAuthenticated) {
    return <PasswordProtect onPasswordSubmit={handlePasswordSubmit} />;
  }

  // Once authenticated, check if the wallet is connected
  if (!connected) {
    return (
      <div className="auth-container">
        <div className="auth-box">
          <h2>You need to connect your wallet to start</h2>
          <WalletMultiButton className="wallet-connect-btn" />
        </div>
      </div>
    );
  }


  return (
    <>
      <Navbar setShowProfile={setShowProfile} />
      <div className="container-fluid">
        <div className="row justify-content-between">
          {showProfile ? (
            <ProfilePage setShowProfile={setShowProfile} />
          ) : (
            <>
              <MDBCol md="3" sm="2" size="2" className={`mb-4 mb-md-0 walletConnectCol ${isWrapped ? 'wrapped' : ''}`}>
                <WalletConnect isWrapped={isWrapped} setSelectedToken={setSelectedToken} />
              </MDBCol>
              <MDBCol size={isWrapped ? "10" : "9"} className="chatRoomCol position-relative">
                <MDBBtn color="light" className="toggle-btn collapseBtn d-none" onClick={() => setIsWrapped(!isWrapped)}>
                  <img src={CollapseIcon} alt="Profile" className="collapseIcon" />
                </MDBBtn>
                {selectedToken && <ChatRoom selectedToken={selectedToken} />}
              </MDBCol>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const App = () => {
  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
  ], []);

  const [selectedToken, setSelectedToken] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [isWrapped, setIsWrapped] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSetSelectedToken = (token) => {
    setSelectedToken(token);
    setShowProfile(false);
  };

  const handlePasswordSubmit = () => {
    setIsAuthenticated(true);
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/check-auth`, { withCredentials: true });
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <ConnectionProvider endpoint="https://morning-muddy-breeze.solana-mainnet.quiknode.pro/26317e4b31e6e7bbd6966605ced0a07e2d935729/">
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="gradient-custom">
            <Router>
              <AppContent
                handlePasswordSubmit={handlePasswordSubmit}
                isAuthenticated={isAuthenticated}
                setSelectedToken={handleSetSelectedToken}
                selectedToken={selectedToken}
                showProfile={showProfile}
                setShowProfile={setShowProfile}
                isWrapped={isWrapped}
                setIsWrapped={setIsWrapped}
              />
            </Router>
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
