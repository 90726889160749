import React, { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBTypography,
  MDBBtn
} from 'mdb-react-ui-kit';
require('dotenv').config();
const apiUrl = process.env.REACT_APP_API_URL || '';

const ProfilePage = ({ setShowProfile }) => {
  const { publicKey } = useWallet();
  const [profile, setProfile] = useState({});
  const location = useLocation();

  const fetchProfile = async () => {
    try {
      if (publicKey) {
        const response = await axios.get(`${apiUrl}/api/user/${publicKey.toString()}`, { withCredentials: true });
        setProfile(response.data);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [publicKey]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('twitterLinked')) {
      fetchProfile();
    }
  }, [location.search]);

  const handleTwitterLogin = () => {
    window.location.href = `${apiUrl}/auth/twitter?publicKey=${publicKey}`;
  };

  const handleUnlinkTwitter = async () => {
    try {
      if (publicKey) {
        await axios.post(`${apiUrl}/api/unlink-twitter`, { publicKey: publicKey.toString() }, { withCredentials: true });
        fetchProfile();
      }
    } catch (error) {
      console.error('Error unlinking Twitter account:', error);
    }
  };

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6" className="offset-md-3">
          <MDBCard>
            <MDBCardHeader className="text-center">
              <MDBTypography tag="h2">Profile</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody>
              {profile.socialsLinked ? (
                <>
                  <img src={profile.profilePicture} alt="Profile" className="rounded-circle mb-3" width="100" />
                  <h4>{profile.username}</h4>
                  <p>Socials verified</p>
                  <MDBBtn onClick={handleUnlinkTwitter} className="mt-3">Unlink Twitter</MDBBtn>
                  <MDBBtn onClick={() => setShowProfile(false)} className="mt-3">Back</MDBBtn>
                </>
              ) : (
                <MDBBtn onClick={handleTwitterLogin} className="mt-3">Link Twitter</MDBBtn>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProfilePage;
