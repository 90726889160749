import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Connection, PublicKey } from '@solana/web3.js';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { MDBCol, MDBCard, MDBCardBody, MDBTypography, MDBBtn} from 'mdb-react-ui-kit';
import './WalletConnect.css';
import './AuthStyles.css';
import glass from '../assets/MagnifyingGlass.png';
import CaretDown from '../assets/CaretDown.png';
import PushPinSimple from '../assets/PushPinSimple.png';
import ChatCircleDots from '../assets/ChatCircleDots.png';
import sol from '../assets/sol.svg';
import eth from '../assets/eth.svg';
import mantle from '../assets/mantle.png';
import monad from '../assets/monad.jpg';
import chatIncoming from '../assets/chatIncoming.png';
import noti from '../assets/noti.png';
import { Circles } from 'react-loader-spinner';
require('dotenv').config();
const apiUrl = process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL_PREVIEW || '';
const WalletConnect = ({ isWrapped, setSelectedToken }) => {
const { publicKey, connected } = useWallet();
const [tokens, setTokens] = useState([]);
const [tokenMetadata, setTokenMetadata] = useState({});
const [latestMessages, setLatestMessages] = useState({});
const [loading, setLoading] = useState(true);
const [randomImage, setRandomImage] = useState(null);
const images = [sol, eth, mantle, monad];

useEffect(() => {
  const selectedImage = images[Math.floor(Math.random() * images.length)];
  setRandomImage(selectedImage); // Set the random image only once when the component mounts
}, [images]);


const loginWithWallet = useCallback(async () => {
  if (publicKey) {
    try {
      await axios.post(`${apiUrl}/api/login`, { publicKey: publicKey.toString() }, { withCredentials: true });
    } catch (error) {
      console.error('Error logging in with wallet:', error);
    }
  }

}, [publicKey]);


useEffect(() => {
  if (publicKey) {
    loginWithWallet();
  }

}, [publicKey, loginWithWallet]);


const fetchTokens = useCallback(async () => {

  setLoading(true);  
  if (connected && publicKey) {

    try {
      const response = await axios.post(`${apiUrl}/api/tokens`, { publicKey: publicKey.toString() });
      const tokens = response.data.map(token => ({
        mint: token.mint,
        amount: formatPrice(token.amount)
      }));

      await fetchTokenMetadata(tokens);

      setLoading(false); 
    } catch (error) {
      console.error('Error fetching tokens:', error);

      setLoading(false);
    }

  }

}, [connected, publicKey]);


  const fetchTokenMetadata = useCallback(async (tokens) => {
    try {
      const metadataPromises = tokens.map(async (token) => {
        try {
          const apiResponse = await axios.get(`${apiUrl}/api/token/${token.mint}`);

          const tokenInfo = apiResponse.data;

          return {
            mint: token.mint,
            name: tokenInfo.name || 'Unknown Token',
            symbol: tokenInfo.symbol || 'N/A',
            usdPrice: tokenInfo.usdPrice || 'N/A',
            marketCap: tokenInfo.marketCap || 'N/A',
            volume24: tokenInfo.volume24 || 'N/A',
            change24: tokenInfo.change24 || 'N/A',
            rawMarketCap: tokenInfo.rawMarketCap || 0,
            links: tokenInfo.links || {},
            logo: tokenInfo.logo || 'N/A'
          };
        } catch (error) {
          console.error(`Error fetching metadata for token ${token.mint} from backend:`, error);

          return {
            mint: token.mint,
            name: 'Unknown Token',
            symbol: 'N/A',
            usdPrice: 'N/A',
            marketCap: 'N/A',
            change24: 'N/A',
            volume24: 'N/A',
            rawMarketCap: 0,
            links: {},
            logo: 'N/A'
          };
        }
      });


      const metadata = await Promise.all(metadataPromises);

    console.log('Fetched metadata:', metadata);
      const filteredMetadata = metadata.filter(token => token.marketCap !== 'N/A');


      const sortedMetadata = filteredMetadata.sort((a, b) => b.rawMarketCap - a.rawMarketCap);


      setTokenMetadata(sortedMetadata.reduce((acc, token) => {
        acc[token.mint] = token;
        return acc;
      }, {}));

      setTokens(sortedMetadata);

    } catch (error) {
      console.error('Error fetching token metadata:', error);
    }

  }, []);

  const fetchLatestMessages = useCallback(async (tokens) => {
    try {
      const latestMessagesPromises = tokens.map(async (token) => {
        try {
          const apiResponse = await axios.get(`${apiUrl}/api/chatroom/${token.mint}/latest-message`);
          const latestMessage = apiResponse.data;
          console.log(`Latest message: ${latestMessage}`)
          return { mint: token.mint, latestMessage };
        } catch (error) {
          if (error.response && error.response.status === 404) {
            return { mint: token.mint, latestMessage: null };
          } else {
            console.error(`Error fetching latest message for ${token.mint}:`, error);
            return { mint: token.mint, latestMessage: null };
          }
        }
      });

      const latestMessagesArray = await Promise.all(latestMessagesPromises);

      const latestMessagesObj = latestMessagesArray.reduce((acc, { mint, latestMessage }) => {
        acc[mint] = latestMessage;
        return acc;
      }, {});

      setLatestMessages(latestMessagesObj);
    } catch (error) {
      console.error('Error fetching latest messages:', error);
    }

  }, []);


  useEffect(() => {
    if (connected && tokens.length > 0) {
      fetchTokenMetadata(tokens);
      fetchLatestMessages(tokens);
    } else {


      fetchTokens();

    }
  }, [connected, publicKey, tokens.length, fetchTokenMetadata, fetchLatestMessages, fetchTokens]);



  const formatPrice = (price) => {
    if (price === 0) return '0.0';
    const log10 = Math.floor(Math.log10(price));
    const divisor = Math.pow(10, log10);
    return (Math.round(price / divisor * 10) / 10 * divisor).toString();
  };

  const CaretDownIcon = useMemo(() =>  (
    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
    </svg>
  ), []);

  const CaretUpIcon = useMemo(() => (
    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M183.6 724h656.8c19.7 0 30.7-20.8 18.5-35L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L162.1 689c-12.2 14.2-1.2 35 18.5 35z"></path>
    </svg>
  ), []);;

  const getChangeColor = (change) => {
    if (!change || change === 'N/A') return 'white';

    const changeNumber = parseFloat(change.replace('%', ''));
    return changeNumber < 0 ? '#9f110f' : '#22721C';
  };

  const formatChange24 = (change) => {
    if (!change || change === 'N/A') return 'N/A';

    const changeNumber = parseFloat(change.replace('%', ''));
    const absChange = Math.abs(changeNumber).toFixed(1); // Keep two decimal places

    return (
      <>
        {changeNumber < 0 ? CaretDownIcon : CaretUpIcon}
        {absChange}%
      </>
    );
  };

  const formatSimpleTimeDistance = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
    };

    for (const [key, value] of Object.entries(intervals)) {
      const count = Math.floor(seconds / value);
      if (count >= 1) {
        if (key === 'minute') return `${count} min ago`;
        if (key === 'hour') return `${count} h ago`;
        if (key === 'day') return `${count} d ago`;
        if (key === 'week') return `${count} week ago`;
        if (key === 'month') return `${count} month ago`;
        if (key === 'year') return `${count} year${count > 1 ? 's' : ''} ago`;
      }
    }
    return 'just now';
  };

  return (

      <MDBCard className="tokensCol">
        <MDBCardBody>
        {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
              <Circles height="80" width="80" color="white" ariaLabel="loading" />
            </div>
          ) : connected ? (
          <MDBTypography listUnStyled className="mb-0 list-tokens">
          <div className="initialChats">
            <div className="tokenColMenu">
              <a className="item active">My Holdings</a>
              <a className="item">Trending</a>
              <a className="item">All</a>
              <a className="item">
                <img src={glass} alt="glass" style={{ width: '24px', height: '24px' }} />
              </a>
            </div>
            <div className="pinnedChats">
              <a className="item"><img src={PushPinSimple} className="pushPin" alt="PushPinSimple" style={{ width: '18px', height: '18px' }} /> Pinned Chats</a>
              <a className="item mostRecent">Most recent <img src={CaretDown} className="caretDown" alt="CaretDown" style={{ width: '18px', height: '18px' }} /></a>
            </div>
              
                {tokens.slice(0, 3).map((token, index) => (
                  <React.Fragment key={index}>
                  <li key={index} className="p-2 tokenCard position-relative" onClick={() => { setSelectedToken(token) }}>
                    <div className="tokenImage">
                      <img src={ tokenMetadata[token.mint]?.logo || 'https://via.placeholder.com/60' } alt="token-logo" className="tokenLogo position-relative"/>
                      {index === 0 && ( 
                        <img src={chatIncoming} alt="chatinc" className="chatIncoming"/>
                        )}
                      {index === 1 && ( 
                        <img src={noti} alt="noti" className="noti"/>
                        )}
                      {index === 3 && ( 
                        <img src={noti} alt="noti" className="noti"/>
                        )}
                    </div>
                    <a href="#!" className="link-light tokenCardContent">
                    {isWrapped ? (
                        <div className="d-flex flex-row align-items-center">
                            <p className="fw-bold mb-0 text-white tokenSymbol">{tokenMetadata[token.mint]?.symbol || 'Unknown Token'}</p>
                        </div>
                    ) : (
                      <>
                          <div className="tokenTitle">
                            <p className="mb-0 tokenSymbol">${tokenMetadata[token.mint]?.symbol || 'Unknown Token'}</p>
                            {index === 0 ? (
                              <img src={eth} alt="glass" className="chainLogo"/>
                            ) : (
                              <img src={randomImage} alt="glass" className="chainLogo" />
                            )}
                          </div>
                          <div className="tokenInfo">
                            <div className="tokenMetrics">
                              <p className="tokenMetricInfo">MC:${tokenMetadata[token.mint]?.marketCap || 'N/A'}</p>
                              <p className="d-flex flex-row tokenMetricInfo">
                                24h:
                                <p style={{ color: getChangeColor(tokenMetadata[token.mint]?.change24) }}>
                                  {formatChange24(tokenMetadata[token.mint]?.change24 || 'N/A')}
                                </p>
                              </p>
                              <p className="tokenMetricInfo">Vol:${tokenMetadata[token.mint]?.volume24 || 'N/A'}</p>
                            </div>
                            {index === 0 ? (
                              <p className="small latestMessage firstOne">
                                {latestMessages[token.mint] ? (
                                  <>
                                    <p className="isTyping">
                                      {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)} is typing...
                                    </p>
                                    <p className="separator">
                                      ·
                                    </p>
                                    <p className="timestamp">
                                      Now
                                    </p>
                                  </>
                                ) : (
                                  <p>
                                  </p>
                                )}
                              </p>
                              ) : (
                              <p className="small latestMessage">
                                {latestMessages[token.mint] ? (
                                  <>
                                    <p>
                                      {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)}:
                                    </p>
                                    <p>
                                    {latestMessages[token.mint].message.startsWith('<img src=') ? (
                                      'Sent a GIF'
                                    ) : (
            
                                      latestMessages[token.mint].message.length > 10 
                                        ? `${latestMessages[token.mint].message.slice(0, 10)}...`
                                        : latestMessages[token.mint].message
                                    )}
                                    </p>
                                    <p className="separator">
                                      ·
                                    </p>
                                    <p className="timestamp">
                                      {latestMessages[token.mint].timestamp ? formatSimpleTimeDistance(new Date(latestMessages[token.mint].timestamp), { addSuffix: false }) : ''}
                                    </p>
                                  </>
                                ) : (
                                  <p>
                                  </p>
                                )}
                              </p>
                            )}
                        </div>
                        <a href="#!" className="d-flex justify-content-between link-light" onClick={() => { setSelectedToken(token) }}>
                          <div className="d-flex flex-row" style={{ width: '100%' }}>
                            <div className="d-flex m-auto flex-column justify-content-between">

                            </div>
                          </div>
                        </a>
                      </>
                    )}
                    </a>
                  </li>
                  {index === 2 && (
                    <div className="pinnedChats">
                      <a className="item"><img src={ChatCircleDots} className="pushPin" alt="PushPinSimple" style={{ width: '18px', height: '18px' }} />Chats</a>
                      <a className="item mostRecent">Most recent <img src={CaretDown} className="caretDown" alt="CaretDown" style={{ width: '18px', height: '18px' }} /></a>
                    </div>
                  )}
                  </React.Fragment>
                ))}
            </div>
            <div className="otherChats">
              {tokens.slice(3).map((token, index) => (
                <React.Fragment key={index + 3}>
                <li key={index} className="p-2 tokenCard position-relative" onClick={() => { setSelectedToken(token) }}>
                  <div className="tokenImage">
                    <img src={ tokenMetadata[token.mint]?.logo || 'https://via.placeholder.com/60' } alt="token-logo" className="tokenLogo position-relative"/>
                    {index === 0 && ( 
                      <img src={chatIncoming} alt="chatinc" className="chatIncoming"/>
                      )}
                    {index === 1 && ( 
                      <img src={noti} alt="noti" className="noti"/>
                      )}
                    {index === 3 && ( 
                      <img src={noti} alt="noti" className="noti"/>
                      )}
                  </div>
                  <a href="#!" className="link-light tokenCardContent" onClick={() => { setSelectedToken(token) }}>
                  {isWrapped ? (
                      <div className="d-flex flex-row align-items-center">
                          <p className="fw-bold mb-0 text-white tokenSymbol">{tokenMetadata[token.mint]?.symbol || 'Unknown Token'}</p>
                      </div>
                  ) : (
                    <>
                        <div className="tokenTitle">
                        <img src={images[Math.floor(Math.random() * images.length)]} alt="glass" className="chainLogo" />
                          <p className="mb-0 tokenSymbol">${tokenMetadata[token.mint]?.symbol || 'Unknown Token'}</p>
                        </div>
                        <div className="tokenInfo">
                          <div className="tokenMetrics">
                            <p className="tokenMetricInfo">MC:${tokenMetadata[token.mint]?.marketCap || 'N/A'}</p>
                            <p className="d-flex flex-row tokenMetricInfo">
                              24h:
                              <p style={{ color: getChangeColor(tokenMetadata[token.mint]?.change24) }}>
                                {formatChange24(tokenMetadata[token.mint]?.change24 || 'N/A')}
                              </p>
                            </p>
                            <p className="tokenMetricInfo">Vol:${tokenMetadata[token.mint]?.volume24 || 'N/A'}</p>
                          </div>
                          {index === 0 ? (
                            <p className="small latestMessage firstOne">
                              {latestMessages[token.mint] ? (
                                <>
                                  <p className="isTyping">
                                    {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)} is typing...
                                  </p>
                                  <p className="separator">
                                    ·
                                  </p>
                                  <p className="timestamp">
                                    Now
                                  </p>
                                </>
                              ) : (
                                <p>
                                </p>
                              )}
                            </p>
                            ) : (
                            <p className="small latestMessage">
                              {latestMessages[token.mint] ? (
                                <>
                                  <p>
                                    {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)}:
                                  </p>
                                  <p>
                                  {latestMessages[token.mint].message.startsWith('<img src=') ? (
                                    'Sent a GIF'
                                  ) : (
                
                                    latestMessages[token.mint].message.length > 10 
                                      ? `${latestMessages[token.mint].message.slice(0, 10)}...`
                                      : latestMessages[token.mint].message
                                  )}
                                  </p>
                                  <p className="separator">
                                    ·
                                  </p>
                                  <p className="timestamp">
                                    {latestMessages[token.mint].timestamp ? formatSimpleTimeDistance(new Date(latestMessages[token.mint].timestamp), { addSuffix: false }) : ''}
                                  </p>
                                </>
                              ) : (
                                <p>
                                </p>
                              )}
                            </p>
                          )}
                      </div>
                      <a href="#!" className="d-flex justify-content-between link-light" onClick={() => { setSelectedToken(token) }}>
                        <div className="d-flex flex-row" style={{ width: '100%' }}>
                          <div className="d-flex m-auto flex-column justify-content-between">

                          </div>
                        </div>
                      </a>
                    </>
                  )}
                  </a>
                </li>
                </React.Fragment>
              ))}
            </div>

          </MDBTypography>
          ) : (
            <div>
              <p className="text-white">Please connect your wallet to see your tokens.</p>
              <WalletMultiButton />
            </div>
          )}
        </MDBCardBody>
      </MDBCard>
  );
};

export default WalletConnect;
